import { template as template_8b195d772f864e3cbd9dc9fd4c1d64be } from "@ember/template-compiler";
const FKLabel = template_8b195d772f864e3cbd9dc9fd4c1d64be(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
