import { template as template_960f44b706a04d11b88207a7f8b5ca03 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_960f44b706a04d11b88207a7f8b5ca03(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
