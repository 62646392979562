import { template as template_73f79948e8c1446287a314e8e49dd2cc } from "@ember/template-compiler";
const SidebarSectionMessage = template_73f79948e8c1446287a314e8e49dd2cc(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
