import { template as template_bfa13c8ef80c4bd997cc9e5a8ce049e6 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_bfa13c8ef80c4bd997cc9e5a8ce049e6(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
