import { template as template_42976220fb7c4bae86a58199a0008d95 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_42976220fb7c4bae86a58199a0008d95(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
